import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '@/design-system/atoms/Button';
import { ButtonGroup } from '@/design-system/atoms/ButtonGroup';
import { ContentContainer } from '@/design-system/atoms/ContentContainer';
import { Grid, GridColumn } from '@/design-system/atoms/Grid';
import { RichTextEditor } from '@/design-system/atoms/RichTextEditor';
import { useIsChild } from '@/design-system/contexts/ContentTreeContext/ContentTreeContext';

import styles from './CopyBlock.module.scss';

/**
 * The Copy Block Component is used to generates static copy and provides
 * authors the ability to add large copy blocks onto the page.
 *
 * The Copy Block can be displayed in two different widths, inset and full width.
 */
const getAnalyticsComponentTitle = (text) => {
    let title = '';
    if (text) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, 'text/html');
        const titleNodes = doc.querySelectorAll('h2, h3, h4, h5, h6');
        if (titleNodes.length > 0) {
            title = titleNodes[0].textContent;
        }
    }
    return title;
};
const CopyBlock = React.forwardRef((props, ref) => {
    const { className, text, ctas, contentWidth, ...otherProps } = props;
    const isInset = contentWidth === 'inset';
    const isChild = useIsChild();
    const [analyticsComponentTitle, setAnalyticsComponentTitle] = React.useState('');

    React.useEffect(() => {
        setAnalyticsComponentTitle(getAnalyticsComponentTitle(text));
    }, [text]);

    return (
        <ContentContainer isFullWidth={isChild}>
            <div ref={ref} className={classnames(styles['copy-block'], className)} {...otherProps}>
                <Grid>
                    <GridColumn
                        colSpan={{
                            sm: 4,
                            md: 6,
                            lg: isInset ? 6 : 12,
                        }}
                    >
                        <div>
                            <RichTextEditor
                                text={text}
                                analytics={{
                                    componentTitle: analyticsComponentTitle,
                                    componentName: 'Copy Block',
                                }}
                            />
                        </div>
                        {ctas && (
                            <div className={styles['copy-block__actions']}>
                                <ButtonGroup
                                    buttons={ctas}
                                    analytics={{
                                        componentTitle: analyticsComponentTitle,
                                        componentName: 'Copy Block',
                                    }}
                                />
                            </div>
                        )}
                    </GridColumn>
                </Grid>
            </div>
        </ContentContainer>
    );
});

CopyBlock.displayName = 'CopyBlock';

/**
 * Content width options
 */
CopyBlock.CONTENT_WIDTH = {
    INSET: 'inset',
    FULL_WIDTH: 'fullWidth',
};

CopyBlock.propTypes = {
    /**
     * HTML content
     */
    text: PropTypes.string,
    /**
     * Array of links
     */
    ctas: PropTypes.arrayOf(PropTypes.shape(Button.prototype)),
    /**
     * Width of the content in the grid
     */
    contentWidth: PropTypes.oneOf(['inset', 'fullWidth']),
};

export default CopyBlock;
